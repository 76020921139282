import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagramSquare, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import {Wrapper, Column} from './styles';


export const Footer = () => {

  const [w, setW] = useState(0);

  useEffect(() => setW(window.innerWidth),[])

  const footData = useStaticQuery(graphql`
  query footInfo {
    site {
      siteMetadata {
        eMail
        address
        phoneNo
        phoneNo1
        nipNumber
        brand
        facebook
        instagram
      }
    }
    allImageSharp(filter: {fluid: {originalName: {eq: "logo.png"}}}) {
      edges {
        node {
          id
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
            originalImg
            originalName
          }
        }
      }
    }
  }`);

  const {brand} = footData.site.siteMetadata;
  const {facebook} = footData.site.siteMetadata;
  const {instagram} = footData.site.siteMetadata;

  const styleImg = {
    width: w > 768 ? '32%' : '48%',
    resizeMode: 'contain',
  }

  return (
    <Wrapper>
      <Column>
        <Img 
          alt="Artis-Pro Logo"
          style = {styleImg} 
          fluid={footData.allImageSharp.edges[0].node.fluid} 
        />
        <article>
          <p>E-mail: {footData.site.siteMetadata.eMail}</p>
          <p>Tel. {footData.site.siteMetadata.phoneNo1}</p>
        </article>
      </Column>
      <Column>
        <article>
          <a href={instagram}>
            <FontAwesomeIcon 
              icon={faInstagramSquare} 
              size="3x"
              color={"#C12C34"}
            />
          </a>
          <a href={facebook}>
            <FontAwesomeIcon 
              icon={faFacebookSquare} 
              size="3x"
              color={"#C12C34"}
            />
          </a>
        <p>&copy;2021 {brand}</p>
        </article>
      </Column>
    </Wrapper>
  );
};
import styled from 'styled-components';

export const Pastile = styled.div`
  width: 340px;
  height: 380px;
  background: #fff;
  cursor: pointer;
  z-index: 1;
  font-family: 'Noto Sans Display', sans-serif;
  text-transform: uppercase;

  @media(max-width: 960px) {
    width: 420px;
    margin-bottom: 18px;
    align-self: center;
  }

  @media(max-width: 425px) {
    max-width: 300px;
  }

  p {
    margin: 0;
    margin-bottom: 10px;
  }

  a {
    margin: 0;
    color: #000;
    text-decoration: underline;
  }
`;


export const Thumb = styled.div`
  text-align: center;
  font-size: 1.6rem;
  color: #000;
  letter-spacing: .5px;
  margin-top: 30px;
  font-family: 'Noto Sans Display', sans-serif;

  @media(min-width: 960px) {
    margin-top: 10px;
    font-size: 1.4rem;
  }
`;
import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import {Pastile, Thumb, Overlay} from './styles';
export const ProductPastile = (props) => {

  return (
    <>
    <Pastile>
      <Img 
        style = {{
          minWidth: '90%',
          minHeight: '70%',
          margin: 15,
        }} 
        fluid={props.image.node.fluid}
      />
      <Thumb>
        <p>{props.info.title}</p>
        <Link to="https://www.olx.pl/d/oferta/ogrodzenia-ploty-zawias-zawiasy-alimiowy-aluminium-system-ogrodzen-CID628-IDMTYuF.html">Zamów</Link>
      </Thumb>
    </Pastile>
    </>
  )
}
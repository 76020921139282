import React from 'react';
import Img from 'gatsby-image';
import {Pastile, Thumb, Overlay} from './styles';
export const ProjectPastile = (props) => {

  return (
    <>
    
    <Pastile>
    <Overlay>
      {props.info.description}
    </Overlay>
      <Img 
        style = {{
          minWidth: '90%',
          minHeight: '70%',
          margin: 15,
        }} 
        fluid={props.image.node.fluid}
      />
      <Thumb>
        <p>{props.info.title}</p>
      </Thumb>
    </Pastile>
    </>
  )
}
import React from 'react';
import { Banner, Section } from './styles';

export const TextBanner = (props) => {

  return (
    <>
      <Banner>
        <h2>Dodatkowe usługi</h2>
        <Section>
          <span>
            <h3>Gięcie blach na prasie krawędziowej</h3>
            <p>Remigiusz</p>
            <p>Tel: +48 608 034 166</p>
          </span>
          <span>
            <h3>Piaskowanie</h3>
            <p>Karol Jarzyński</p>
            <p>Tel: +48 535 334 815</p>
          </span>
        </Section>
      </Banner>
    </>
  )
}
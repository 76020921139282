import styled from 'styled-components';

export const Banner = styled.div`
  color: #fff;
  text-align: center;
  width: 90%;
  margin-top: 40px;
  margin-bottom: 40px;


  h2 {
    text-align: center;
    font-size: 42px;
    color: #fff;
    width: 100%;
    margin-top: 20px;
  }
`

export const Section = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-size: 28px;
      max-width: 80%;
      align-self: center;
    }

    p {
      font-size: 20px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
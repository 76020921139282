import React from 'react';
import { Link } from 'gatsby';
import NavbarLinks from '../NavbarLinks';
import { Container } from '../../../common';
import { Wrapper, Brand, Image } from './styles';

import logo from '../../../../images/main-logo.png'

const Navbar = () => {
  return (
    <Wrapper as={Container}>
      <Brand as={Link} to="/">
        <Image src={logo} />
      </Brand>
      <NavbarLinks desktop />
    </Wrapper>
  )
}

export default Navbar;
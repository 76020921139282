import styled from 'styled-components';

export const Pastile = styled.div`
  width: 340px;
  height: 380px;
  background: #fff;
  cursor: pointer;
  z-index: 1;
  font-family: 'Noto Sans Display', sans-serif;
  text-transform: uppercase;

  @media(max-width: 960px) {
    width: 420px;
    margin-bottom: 18px;
    align-self: center;
  }

  @media(max-width: 425px) {
    max-width: 300px;
  }
`;

export const Overlay = styled.div`

width: 300px;
height: 340px;
background: #5B5B5B;
z-index: 30;
position: absolute;
display: flex;
opacity: 0;
cursor: pointer;
transition: 0.1s linear all;
color: #fff;
font-size: 1.2rem;
text-align: justify;
padding: 20px;
justify-content: center;
align-items: center;
font-family: 'Noto Sans Display', sans-serif;

&:hover {
  opacity: 0.95;
}

@media (max-width: 1024px) {
  width: 250px;
}

@media(max-width: 968px) {
  width: 380px;
  margin-bottom: 18px;
  align-self: center;
  font-size: 1.5rem;
}

@media(max-width: 425px) {
  max-width: 260px;
  font-size: 1.1rem;
}
`;


export const Thumb = styled.div`
  text-align: center;
  font-size: 1.6rem;
  color: #000;
  letter-spacing: .5px;
  margin-top: 30px;
  font-family: 'Noto Sans Display', sans-serif;

  @media(min-width: 960px) {
    margin-top: 10px;
    font-size: 1.4rem;
  }
`;
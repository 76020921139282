import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  width: 100%;
  padding: 6px 0 6px 0;
  display: flex;
  flex-direction: row;
  font-family: 'Noto Sans Display', sans-serif;

  @media (max-width: 968px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  width: 50%;
  color: #5B5B5B;
  padding: 10px;
  // border: 1px solid blue;
  align-items: left;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;

  @media (max-width: 968px) {
    width: 100%;
    padding: 0;
    align-items: center;
  }

  &:nth-child(2) {
    justify-content: flex-end;
    align-items: right;
    @media (max-width: 968px) {
      align-items: center;
      justify-content: center;
    }
  }

  &:nth-child(2) p {
    text-align: right;
    @media (max-width: 968px) {
      text-align: center;
    }
  }

  article {
    margin: 0;
    

    &:nth-child(1) {
      text-align: right;

      @media (max-width: 968px) {
        text-align: center;
      }
    }

    &:nth-child(1) p {
      width: 98%;
      padding-right: 10px;
    }
  }

  p {
    width: 30%;
    margin: 4px;
    font-size: 1.08rem;
    text-align: center;
    letter-spacing: 0.7px;

    @media (max-width: 968px) {
      width: 100%;
    }
  }

  a {
    color: #5B5B5B;
    width: 40px;
    height: 40px;
    margin: 10px;
    transition: all .2s ease-in-out;
  }

`;
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
`;

export const Brand = styled.a`
  height: 46px;
  @media (max-width: 960px) { 
    mix-blend-mode: unset;
    height: 30px;
  }
`;

export const Image = styled.img`
  resize-mode: cover;
  height: 46px;
  margin:0;
  @media (max-width: 960px) { 
    height: 30px;
  }
`;
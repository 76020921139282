import React from 'react';
import { Link } from 'gatsby';
import { Wrapper } from './styles';

const NavbarLinks = ({ desktop }) => {
  return (
    <Wrapper desktop={desktop}>
      <Link to="/">Strona główna</Link>
      <Link to="/gall">Galeria</Link>
      <Link to="/contact">Kontakt</Link>
    </Wrapper>
  )
};

export default NavbarLinks;